.baijamjureeextralight {
  font-family: "Bai Jamjuree", serif;
  font-weight: 200;
  font-style: normal;
}

.baijamjureemedium {
  font-family: "Bai Jamjuree", serif;
  font-weight: 500;
  font-style: normal;
}


.baijamjureelight {
  font-family: "Bai Jamjuree", serif;
  font-weight: 300;
  font-style: normal;
}


.baijamjureeregular {
  font-family: "Bai Jamjuree", serif;
  font-weight: 400;
  font-style: normal;
}

.intermed {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.interextramed {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}